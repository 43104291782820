@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

body {
  background-color: #fff;
  font-family: 'Poppins', sans-serif;
}

button {
  font-family: 'Poppins', sans-serif;
}

main {
  font-family: 'Poppins', sans-serif;
}

h1 {
  margin: 5px;
  font-family: 'Poppins', sans-serif;
}

h2 {
  margin: 2px;
}

a {
  font-family: "Playfair Display", serif;
  margin: 2px
}

.App-link {
  color: #457B9D;
}
